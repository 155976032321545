<template>
    <Toppage />
</template>

<script>
// @ is an alias to /src
import Toppage from '@/components/Toppage.vue'

export default {
  name: 'HomeView',
  components: {
    Toppage
  }
}
</script>
